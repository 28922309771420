import React from "react";
import "./Reasons.css";
import l2 from "../../assets/L2.jpg";
import l4 from "../../assets/L4.jpg";
import l6 from "../../assets/L6.jpg";
import l5 from "../../assets/L5.jpg";
import ticks from "../../assets/tick.png"

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-side">
        <img src={l2} alt="" style={{ width: 400, height: 270 }} />
        <img src={l4} alt="" style={{ width: 400, height: 270 }} />
        <img src={l6} alt="" style={{ width: 400, height: 270 }} />
        <img src={l5} alt="" style={{ width: 400, height: 270 }} />
      </div>
      <div className="right-side">
        <span style={{fontSize: 30}}>Some Reasons</span>
        <div className="why-choose">
          <span className="stoke-text">Why</span>
          <span>Choose Us?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={ticks} alt=""></img>
            <span>Deliver On Time</span>
          </div>
          <div>
            <img src={ticks} alt="" />
            <span>Best Quality Product</span>
          </div>
          <div>
            <img src={ticks} alt="" />
            <span>Special Discount On Bulk Quantity</span>
          </div>
          <div>
            <img src={ticks} alt="" />
            <span>Work According to your Requirements</span>
          </div>
        </div>
        <span style={{
            color: 'var(--gray)',
            fontWeight: 'normal',
        }}>OUR PARTNERS
        </span>
        <div className="partners">
            <img src="" alt="" />
            <img src="" alt="" />
            <img src="" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
