
import './App.css';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Join from './components/Join/Join';
import Plans from './components/Plans/Plans';
import Reasons from './components/Reasons/Reasons';
import Services from './components/Services/Services';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
     <Hero />
     <Services />
     <Reasons />
     <Plans />
     <Testimonials />
     <Join />
     <Footer />
    </div>
  );
}

export default App;
