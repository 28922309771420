import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import press1 from "../../assets/press1.png";
import calories from "../../assets/calories.png";
import prate from "../../assets/p-rate.png";
// import prate2 from "../../assets/p-rate2png"
import { motion } from "framer-motion";
import NumberCounter from "number-counter";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth<=768 ? true : false;
  return (
    <div className="Hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* best ad  */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile? "165px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>The Best Service Provider in the town</span>
        </div>
        {/* hero heading  */}
        <div className="hero-text">
          <div>
            <span>Custom </span>
            <span className="stoke-text">Labels</span>
          </div>
          <div>
            <span> for your Apparel</span>
          </div>
          <div>
            <span>
              I will make custom clothing labels for your apparel and clothing
              line.{" "}
            </span>
            <span>
              I am a direct manufacturer of high-quality labels dealing with all
              types of labels, You can order your labels in any size, color and
              styles.
            </span>
          </div>
        </div>

        {/* figures  */}

        <div className="figures">
          <div>
            <span >
              <NumberCounter end={99} start={60} delay="4" preFix="+" />
            </span>
            <span>Expert Workers</span>
          </div>
          <div>
            <span>
            <NumberCounter end={50} start={30} delay="4" preFix="+" />
               </span>
            <span>Working Departments</span>
          </div>
          <div>
            <span>
            <NumberCounter end={1000} start={850} delay="4" preFix="+" />
            </span>
            <span>Satisfied Customers</span>
          </div>
        </div>

        {/* hero buttons  */}

        <div className="hero-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div
        initial={{ right: "-1rem" }}
        whileInView={{ right: "4rem" }}
        transition={transition}
         className="p-rate">
          <img src={prate} alt="" style={{ width: 120 }} />
          <div>
            <span>Offset</span>
            <span>Printing</span>
          </div>
        </motion.div>

        {/* hero images  */}
        <img src={press1} alt="" className="hero-press" />
        <motion.div
        initial={{ right: "37rem" }}
        whileInView={{ right: "50rem" }}
        transition={transition} 
         className="vouchers">
          <img src={calories} alt="" />
          <div>
            <span>Fast Growing</span>
            <span>Buissness</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
