import React from 'react'
import "./Services.css"
import { programsData } from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'

const Services = () => {
  return (
    <div className='Services' id='services'>
      <div className="service-header">
        <span className='stoke-text'>Explore Our</span>
        <span>Services</span>
        <span className='stoke-text'>to Grow your Buissness</span>
      </div>
      <div className="service-categories">
        {programsData.map((data)=>(
          <div className="category">
            {data.image}
            <span>{data.heading}</span>
            <span>{data.details}</span>
            <div className="join-now"><span>Order Now</span><img src={RightArrow} alt="" /></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services